import { safeParseEmissions } from '../GHGContext/GHGUtils';

export const safeParseNumber = (value) => {
  if (typeof value === 'number') return value;
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  }
  return 0;
};

export const calculateTotals = (emissionsData) => {
  if (!emissionsData) {
    return {
      scope1Total: 0,
      scope2Total: {
        locationBased: 0,
        marketBased: 0
      },
      scope3Total: 0,
      totalEmissions: {
        locationBased: 0,
        marketBased: 0
      }
    };
  }

  try {
    // Calculate Scope 1 total
    const scope1Total = Object.values(emissionsData.scope1 || {}).reduce((total, source) => {
      if (!source?.data?.length) return total;
      return total + source.data.reduce(
        (sum, entry) => sum + safeParseEmissions(entry.co2e_emissions),
        0
      );
    }, 0);

    // Calculate Scope 2 totals for both methods
    const scope2 = {
      locationBased: 0,
      marketBased: 0
    };

    // Process purchased electricity with enhanced method handling
    if (emissionsData.scope2?.purchasedElectricityLB?.data) {
      emissionsData.scope2.purchasedElectricityLB.data.forEach(entry => {
        // Initialize values for both methods
        let locationValue = 0;
        let marketValue = 0;

        // Location-based calculation with comprehensive method checking
        if (entry.method === 'Location-based' || entry.method === 'Dual') {
          locationValue = safeParseEmissions(entry.co2e_emissions);
        } else if (entry.locationBasedEmissions) {
          locationValue = safeParseEmissions(entry.locationBasedEmissions);
        }

        // Market-based calculation with proper fallback
        if (entry.marketBasedData?.marketBasedEmissions) {
          marketValue = safeParseEmissions(entry.marketBasedData.marketBasedEmissions);
        } else if (entry.method === 'Market-based') {
          marketValue = safeParseEmissions(entry.co2e_emissions);
        } else {
          marketValue = locationValue; // Use location-based as fallback
        }

        scope2.locationBased += locationValue;
        scope2.marketBased += marketValue;
      });
    }

    // Add purchased heating to both totals (typically same for both methods)
    if (emissionsData.scope2?.purchasedHeating?.data) {
      emissionsData.scope2.purchasedHeating.data.forEach(entry => {
        const value = safeParseEmissions(entry.co2e_emissions);
        scope2.locationBased += value;
        scope2.marketBased += value;
      });
    }

    // Calculate Scope 3 total
    const scope3Total = Object.values(emissionsData.scope3 || {}).reduce((total, source) => {
      if (!source?.data?.length) return total;
      return total + source.data.reduce(
        (sum, entry) => sum + safeParseEmissions(entry.co2e_emissions),
        0
      );
    }, 0);

    // Calculate total emissions for both methods
    const totalEmissions = {
      locationBased: scope1Total + scope2.locationBased + scope3Total,
      marketBased: scope1Total + scope2.marketBased + scope3Total
    };

    return {
      scope1Total,
      scope2Total: scope2,
      scope3Total,
      totalEmissions
    };
  } catch (error) {
    console.error('Error calculating totals:', error);
    return {
      scope1Total: 0,
      scope2Total: {
        locationBased: 0,
        marketBased: 0
      },
      scope3Total: 0,
      totalEmissions: {
        locationBased: 0,
        marketBased: 0
      }
    };
  }
};

export const processTimeSeriesData = (emissionsData, baseYearData) => {
  if (!emissionsData) return [];

  try {
    const timeSeriesMap = new Map();

    const processEntries = (entries, scope) => {
      if (!Array.isArray(entries)) return;

      entries.forEach(entry => {
        if (!entry.startDate) return;

        const date = new Date(entry.startDate);
        const dateKey = date.toISOString().split('T')[0];

        if (!timeSeriesMap.has(dateKey)) {
          timeSeriesMap.set(dateKey, {
            date: dateKey,
            scope1: 0,
            scope2_location: 0,
            scope2_market: 0,
            scope3: 0
          });
        }

        const timeSeriesEntry = timeSeriesMap.get(dateKey);
        const emissions = safeParseEmissions(entry.co2e_emissions);

        switch (scope) {
          case 'scope1':
            timeSeriesEntry.scope1 += emissions;
            break;
          case 'scope2':
            // Initialize emissions for both methods
            let locationEmissions = 0;
            let marketEmissions = 0;

            // Log the entry details for debugging
            console.log('Processing scope2 entry:', {
              source: entry.source,
              category: entry.category,
              method: entry.method,
              emissions: entry.co2e_emissions,
              locationBasedEmissions: entry.locationBasedEmissions,
              marketBasedEmissions: entry.marketBasedData?.marketBasedEmissions
            });

            // Handle purchased heating separately
            const isPurchasedHeating = (
              entry.source === 'Purchased Heating' || 
              entry.category === 'purchasedHeating' ||
              entry.source === 'District Heating' ||
              entry.category === 'districtHeating' ||
              entry.source?.toLowerCase().includes('heating') ||
              entry.category?.toLowerCase().includes('heating')
            );

            if (isPurchasedHeating) {
              const heatingValue = safeParseEmissions(entry.co2e_emissions);
              locationEmissions = heatingValue;
              marketEmissions = heatingValue;
              console.log('Processing purchased heating:', {
                date: dateKey,
                source: entry.source,
                category: entry.category,
                heatingValue,
                locationEmissions,
                marketEmissions
              });
            } else {
              // Handle location-based emissions for electricity
              if (entry.method === 'Location-based' || entry.method === 'Dual') {
                locationEmissions = safeParseEmissions(entry.co2e_emissions);
              } else if (entry.locationBasedEmissions) {
                locationEmissions = safeParseEmissions(entry.locationBasedEmissions);
              }

              // Handle market-based emissions for electricity
              if (entry.marketBasedData?.marketBasedEmissions) {
                marketEmissions = safeParseEmissions(entry.marketBasedData.marketBasedEmissions);
              } else if (entry.method === 'Market-based') {
                marketEmissions = safeParseEmissions(entry.co2e_emissions);
              } else {
                // If no market data, use location-based as fallback
                marketEmissions = locationEmissions;
              }

              console.log('Processing electricity:', {
                date: dateKey,
                method: entry.method,
                emissions: entry.co2e_emissions,
                locationEmissions,
                marketEmissions,
                locationBasedEmissions: entry.locationBasedEmissions,
                marketBasedEmissions: entry.marketBasedData?.marketBasedEmissions
              });
            }

            const previousScope2Location = timeSeriesEntry.scope2_location;
            const previousScope2Market = timeSeriesEntry.scope2_market;

            timeSeriesEntry.scope2_location += locationEmissions;
            timeSeriesEntry.scope2_market += marketEmissions;

            console.log('Updated scope2 totals:', {
              date: dateKey,
              previousScope2Location,
              newScope2Location: timeSeriesEntry.scope2_location,
              previousScope2Market,
              newScope2Market: timeSeriesEntry.scope2_market,
              addedLocationEmissions: locationEmissions,
              addedMarketEmissions: marketEmissions,
              isPurchasedHeating
            });
            break;
          case 'scope3':
            timeSeriesEntry.scope3 += emissions;
            break;
          default:
            break;
        }
      });
    };

    // Process each scope
    Object.entries(emissionsData).forEach(([scope, categories]) => {
      console.log('Processing scope:', scope, 'categories:', Object.keys(categories));
      
      // Special handling for scope2 to ensure purchased heating is processed
      if (scope === 'scope2') {
        // Process purchased heating first
        if (categories.purchasedHeating?.data) {
          console.log('Found purchased heating data:', categories.purchasedHeating.data);
          processEntries(categories.purchasedHeating.data, scope);
        }
        
        // Then process purchased electricity
        if (categories.purchasedElectricityLB?.data) {
          console.log('Found purchased electricity data:', categories.purchasedElectricityLB.data);
          processEntries(categories.purchasedElectricityLB.data, scope);
        }
      } else {
        // Process other scopes normally
        Object.entries(categories).forEach(([category, sourceData]) => {
          console.log('Processing category:', category, 'data:', sourceData?.data);
          if (sourceData?.data) {
            processEntries(sourceData.data, scope);
          }
        });
      }
    });

    // Convert map to array and sort by date
    const timeSeriesArray = Array.from(timeSeriesMap.values())
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map(entry => {
        // Log each entry's scope2 values before calculating totals
        console.log('Entry before totals:', {
          date: entry.date,
          scope2_location: entry.scope2_location,
          scope2_market: entry.scope2_market
        });

        return {
          ...entry,
          // Calculate totals for both methods
          total_location: entry.scope1 + entry.scope2_location + entry.scope3,
          total_market: entry.scope1 + entry.scope2_market + entry.scope3
        };
      });

    console.log('Final time series data:', timeSeriesArray);
    return timeSeriesArray;
  } catch (error) {
    console.error('Error processing time series data:', error);
    return [];
  }
};

export default {
  safeParseNumber,
  calculateTotals,
  processTimeSeriesData
};