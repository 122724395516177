// GHGContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  fetchBaseYearData as fetchBaseYearService,
  updateBaseYear as setNewBaseYearService,
  processBaseYearData,
  validateBaseYearData
} from './GHGContext/GHGBaseYearService';

const GHGContext = createContext();

export const useGHG = () => {
  const context = useContext(GHGContext);
  if (!context) {
    throw new Error('useGHG must be used within a GHGProvider');
  }
  return context;
};

export const GHGProvider = ({ children }) => {
  const navigate = useNavigate();
  const [baseYear, setBaseYear] = useState(null);
  const [allBaseYears, setAllBaseYears] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Check authentication
  const checkAuth = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login', { state: { returnUrl: window.location.pathname } });
      return false;
    }
    return true;
  };

  // Handle API errors
  const handleAPIError = (error) => {
    console.error('API Error:', error);
    const requiresAuth = error.message?.includes('Authentication required') || 
                        error.response?.status === 401;
    
    if (requiresAuth) {
      localStorage.removeItem('token');
      navigate('/login', { state: { returnUrl: window.location.pathname } });
    }

    return { requiresAuth };
  };

  // Fetch base year data
  const fetchBaseYear = async (force = false) => {
    if (!checkAuth()) {
      return { baseYear: null, availableYears: [] };
    }

    try {
      setIsLoading(true);
      setError(null);

      const result = await fetchBaseYearService(force);
      
      if (result.current) {
        setBaseYear(result.current);
      }
      setAllBaseYears(result.availableYears || []);

      return result;
    } catch (error) {
      const { requiresAuth } = handleAPIError(error);
      if (!requiresAuth) {
        setError(error.message || 'Failed to fetch base year data');
      }
      return { baseYear: null, availableYears: [] };
    } finally {
      setIsLoading(false);
      setIsInitialLoad(false);
    }
  };

  // Set new base year
  const setNewBaseYear = async (yearData) => {
    if (!checkAuth()) return null;

    try {
      setIsLoading(true);
      setError(null);

      const response = await setNewBaseYearService(yearData);
      
      if (response) {
        setBaseYear(response);
        const result = await fetchBaseYear(true);
        setAllBaseYears(result.availableYears || []);
      }

      return response;
    } catch (error) {
      const { requiresAuth } = handleAPIError(error);
      if (!requiresAuth) {
        setError(error.message || 'Failed to set base year');
      }
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // Initial data load
  useEffect(() => {
    if (isInitialLoad) {
      fetchBaseYear();
    }
  }, [isInitialLoad]);

  const value = {
    baseYear,
    allBaseYears,
    isLoading,
    error,
    initialized: !isInitialLoad,
    fetchBaseYear,
    setNewBaseYear
  };

  return (
    <GHGContext.Provider value={value}>
      {children}
    </GHGContext.Provider>
  );
};