import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';

import {
  API_BASE_URL,
  CACHE_DURATION,
  MIN_REFRESH_INTERVAL,
  MAX_RETRIES,
  RETRY_DELAY,
  ENDPOINTS,
  initialEmissionsData,
  initialBaseYearData,
  getInitialDateRange
} from './UnifiedGHGContext/UnifiedGHGContextPart2';

import {
  safeParseNumber,
  calculateTotals,
  processTimeSeriesData
} from './UnifiedGHGContext/UnifiedGHGContextPart3';

const UnifiedGHGContext = createContext(null);

export const useUnifiedGHG = () => {
  const context = useContext(UnifiedGHGContext);
  if (!context) {
    throw new Error('useUnifiedGHG must be used within a UnifiedGHGProvider');
  }
  return context;
};

export const UnifiedGHGProvider = ({ children }) => {
  const mountedRef = useRef(false);
  const fetchInProgress = useRef(false);
  const lastFetchRef = useRef(null);
  const initializationAttempted = useRef(false);
  const authCheckInterval = useRef(null);
  const dataCache = useRef({});
  const baseYearFetchTimeout = useRef(null);
  
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [emissionsData, setEmissionsData] = useState(initialEmissionsData);
  const [baseYearData, setBaseYearData] = useState(initialBaseYearData);
  const [dateRange, setDateRange] = useState(getInitialDateRange);
  const [earliestDate, setEarliestDate] = useState(null);
  const [latestDate, setLatestDate] = useState(null);

  const clearCache = useCallback(() => {
    dataCache.current = {};
  }, []);

  const debouncedSetLoading = useCallback(
    debounce((value) => {
      if (mountedRef.current) {
        setLoading(value);
      }
    }, 300),
    []
  );

  const shouldRefreshData = useCallback(() => {
    const now = Date.now();
    if (fetchInProgress.current) return false;
    if (!lastFetchRef.current) return true;
    if (now - lastFetchRef.current < MIN_REFRESH_INTERVAL) return false;
    return now - lastFetchRef.current > CACHE_DURATION;
  }, []);

  const makeRequest = useCallback(async (endpoint, options = {}) => {
    let attempt = 0;
    
    while (attempt < MAX_RETRIES) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication required');
        }

        const skipCache = endpoint === '/api/base-year' || options.skipCache;
        const cacheKey = `${endpoint}${options.body ? JSON.stringify(options.body) : ''}`;
        
        if (!skipCache) {
          const cachedData = dataCache.current[cacheKey];
          if (cachedData && Date.now() - cachedData.timestamp < CACHE_DURATION) {
            return cachedData.data;
          }
        }

        // Ensure endpoint is properly formatted
        let apiEndpoint = endpoint;
        if (!endpoint.startsWith('/api/')) {
          apiEndpoint = `/api/${endpoint.startsWith('/') ? endpoint.slice(1) : endpoint}`;
        }
        const url = `${API_BASE_URL}${apiEndpoint}`;

        const response = await fetch(url, {
          ...options,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...options.headers
          }
        });

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error('Authentication required');
          }
          if (response.status === 404) {
            if (options.suppressNotFound) {
              return null;
            }
            console.warn(`Resource not found: ${url}`);
            return [];
          }
          throw new Error(`Request failed: ${response.statusText}`);
        }

        const data = await response.json();
        
        if (!skipCache) {
          dataCache.current[cacheKey] = {
            data,
            timestamp: Date.now()
          };
        }
        
        return data;

      } catch (error) {
        attempt++;
        if (attempt === MAX_RETRIES || error.message === 'Authentication required') {
          throw error;
        }
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * attempt));
      }
    }
  }, []);

  const findDateBoundaries = useCallback((data) => {
    let earliest = null;
    let latest = null;

    const processEntry = (entry) => {
      if (!entry?.startDate || !entry?.co2e_emissions) return;
      
      // Only process entries that have actual emissions data
      const emissions = parseFloat(entry.co2e_emissions);
      if (isNaN(emissions) || emissions === 0) return;

      // Get both start and end dates
      const startDate = new Date(entry.startDate);
      const endDate = entry.endDate ? new Date(entry.endDate) : startDate;
      
      // Update earliest date based on start date
      if (!earliest || startDate < earliest) earliest = startDate;
      
      // Update latest date based on end date, but only if it's not in the future
      const now = new Date();
      if (!latest || (endDate > latest && endDate <= now)) latest = endDate;
    };

    Object.values(data).forEach(scope => {
      Object.values(scope).forEach(category => {
        if (Array.isArray(category.data)) {
          category.data.forEach(processEntry);
        }
      });
    });

    return { earliest, latest };
  }, []);

  const batchFetchEmissionsData = useCallback(async () => {
    if (!mountedRef.current || fetchInProgress.current) return;

    try {
      fetchInProgress.current = true;

      // Filter out non-emissions endpoints first
      const validEndpoints = ['scope1', 'scope2', 'scope3'];
      const requests = Object.entries(ENDPOINTS)
        .filter(([scope]) => validEndpoints.includes(scope))
        .flatMap(([scope, endpoints]) =>
          Object.entries(endpoints).map(([key, endpoint]) => ({
            scope,
            key,
            endpoint: endpoint.toString(),
          }))
        );

      const results = await Promise.all(
        requests.map(async ({ scope, key, endpoint }) => {
          try {
            const data = await makeRequest(endpoint, { suppressNotFound: true });
            return { scope, key, data, error: null };
          } catch (error) {
            console.warn(`Error fetching ${endpoint}:`, error.message);
            return { scope, key, data: [], error };
          }
        })
      );

      if (!mountedRef.current) return;

      const newData = { ...initialEmissionsData };
      let hasValidData = false;

      results.forEach(({ scope, key, data, error }) => {
        if (!newData[scope]) newData[scope] = {};
        const processedData = Array.isArray(data) ? data : data?.entries || [];
        if (processedData.length > 0) hasValidData = true;
        
        newData[scope][key] = {
          data: processedData,
          lastUpdated: new Date(),
          loading: false,
          error: error?.message
        };
      });

      if (hasValidData) {
        const { earliest, latest } = findDateBoundaries(newData);
        if (earliest) setEarliestDate(earliest);
        if (latest) setLatestDate(latest);
        setEmissionsData(newData);
      }

    } finally {
      if (mountedRef.current) {
        fetchInProgress.current = false;
      }
    }
  }, [makeRequest, findDateBoundaries]);

  const calculateBaseYearTotals = useCallback((emissionsData, year) => {
    if (!emissionsData || !year) return null;

    // Helper function to calculate total for a category
    const calculateTotal = (data, method = 'Location-based') => {
      if (!Array.isArray(data)) return 0;
      
      const yearData = data.filter(entry => {
        const entryYear = new Date(entry.startDate).getFullYear();
        return entryYear === parseInt(year);
      });

      // Return 0 if no data for the year
      if (yearData.length === 0) return 0;

      return yearData.reduce((sum, entry) => {
        if (method === 'Market-based' && entry.marketBasedData?.marketBasedEmissions) {
          const emissions = parseFloat(entry.marketBasedData.marketBasedEmissions);
          return sum + (isNaN(emissions) ? 0 : emissions);
        } else {
          const emissions = parseFloat(entry.co2e_emissions || 0);
          return sum + (isNaN(emissions) ? 0 : emissions);
        }
      }, 0);
    };

    // Calculate Scope 1 total
    const scope1Total = Number((
      calculateTotal(emissionsData.scope1?.stationaryCombustion?.data) +
      calculateTotal(emissionsData.scope1?.mobileCombustionLandFB?.data) +
      calculateTotal(emissionsData.scope1?.mobileCombustionLandDB?.data) +
      calculateTotal(emissionsData.scope1?.fugitiveEmissions?.data) +
      calculateTotal(emissionsData.scope1?.processEmissions?.data)
    ).toFixed(2));

    // Calculate Scope 2 totals (both location-based and market-based)
    const scope2LocationTotal = Number((
      calculateTotal(emissionsData.scope2?.purchasedElectricityLB?.data, 'Location-based') +
      calculateTotal(emissionsData.scope2?.purchasedHeating?.data)
    ).toFixed(2));

    const scope2MarketTotal = Number((
      calculateTotal(emissionsData.scope2?.purchasedElectricityLB?.data, 'Market-based') +
      calculateTotal(emissionsData.scope2?.purchasedHeating?.data)
    ).toFixed(2));

    // Calculate Scope 3 total
    const scope3Total = Number((
      calculateTotal(emissionsData.scope3?.wasteGenerated?.data) +
      calculateTotal(emissionsData.scope3?.businessTravelLandDB?.data) +
      calculateTotal(emissionsData.scope3?.employeeCommutingLandDB?.data) +
      calculateTotal(emissionsData.scope3?.upstreamLeasedAssets?.data)
    ).toFixed(2));

    // Calculate total emissions for both methods
    const totalEmissionsLocation = Number((scope1Total + scope2LocationTotal + scope3Total).toFixed(2));
    const totalEmissionsMarket = Number((scope1Total + scope2MarketTotal + scope3Total).toFixed(2));

    // Add debug logging
    console.log('Base Year Calculations:', {
      year,
      scope1: {
        total: scope1Total,
        sources: {
          stationaryCombustion: calculateTotal(emissionsData.scope1?.stationaryCombustion?.data),
          mobileCombustionLandFB: calculateTotal(emissionsData.scope1?.mobileCombustionLandFB?.data),
          mobileCombustionLandDB: calculateTotal(emissionsData.scope1?.mobileCombustionLandDB?.data),
          fugitiveEmissions: calculateTotal(emissionsData.scope1?.fugitiveEmissions?.data),
          processEmissions: calculateTotal(emissionsData.scope1?.processEmissions?.data)
        }
      },
      scope2: {
        locationTotal: scope2LocationTotal,
        marketTotal: scope2MarketTotal,
        sources: {
          purchasedElectricityLocation: calculateTotal(emissionsData.scope2?.purchasedElectricityLB?.data, 'Location-based'),
          purchasedElectricityMarket: calculateTotal(emissionsData.scope2?.purchasedElectricityLB?.data, 'Market-based'),
          purchasedHeating: calculateTotal(emissionsData.scope2?.purchasedHeating?.data)
        }
      },
      scope3: {
        total: scope3Total,
        sources: {
          wasteGenerated: calculateTotal(emissionsData.scope3?.wasteGenerated?.data),
          businessTravel: calculateTotal(emissionsData.scope3?.businessTravelLandDB?.data),
          employeeCommuting: calculateTotal(emissionsData.scope3?.employeeCommutingLandDB?.data),
          upstreamLeasedAssets: calculateTotal(emissionsData.scope3?.upstreamLeasedAssets?.data)
        }
      }
    });

    return {
      year: year.toString(),
      scope1_total: scope1Total,
      scope2_total_location: scope2LocationTotal,
      scope2_total_market: scope2MarketTotal,
      scope3_total: scope3Total,
      total_emissions_location: totalEmissionsLocation,
      total_emissions_market: totalEmissionsMarket
    };
  }, []);

  const fetchBaseYearData = useCallback(async (skipCache = false) => {
    try {
      const data = await makeRequest('/api/base-year', { 
        suppressNotFound: true,
        skipCache 
      });
      
      if (!mountedRef.current) return;

      // Calculate totals for all years to find valid ones
      const calculateYearTotals = (year) => {
        const totals = calculateBaseYearTotals(emissionsData, year);
        if (!totals) return null;

        // A year is valid if it has any emissions data
        const hasAnyEmissions = 
          totals.scope1_total > 0 || 
          totals.scope2_total_location > 0 || 
          totals.scope2_total_market > 0 ||
          totals.scope3_total > 0;
        
        return hasAnyEmissions ? totals : null;
      };

      // Process current base year if it exists
      let current = null;
      if (data?.current?.year) {
        current = calculateYearTotals(data.current.year);
      }

      // Process available years
      const availableYears = data?.availableYears
        ?.map(year => calculateYearTotals(year.year))
        .filter(Boolean) || [];

      console.log('Processed Base Year Data:', {
        current,
        availableYears,
        emissionsData
      });

      setBaseYearData({
        current,
        availableYears,
        loading: false,
        error: null
      });
    } catch (error) {
      console.error('Error fetching base year data:', error);
      if (mountedRef.current) {
        setBaseYearData(prev => ({
          ...prev,
          loading: false,
          error: error.message
        }));
      }
    }
  }, [makeRequest, emissionsData, calculateBaseYearTotals]);

  const handleDateRangeChange = useCallback((newRange) => {
    // Ensure we have valid dates
    if (!newRange?.start || !newRange?.end) return;

    // Format dates consistently
    const formattedRange = {
      start: new Date(newRange.start),
      end: new Date(newRange.end)
    };

    // Save to localStorage immediately
    localStorage.setItem('ghg_date_range', JSON.stringify(formattedRange));
    
    // Update context state
    setDateRange(formattedRange);
  }, []);

  // Initialize date range on mount
  useEffect(() => {
    const initialRange = getInitialDateRange();
    handleDateRangeChange(initialRange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Update date range when URL changes (page navigation)
  useEffect(() => {
    const savedRange = localStorage.getItem('ghg_date_range');
    if (savedRange) {
      try {
        const range = JSON.parse(savedRange);
        setDateRange({
          start: new Date(range.start),
          end: new Date(range.end)
        });
      } catch (e) {
        console.warn('Failed to parse saved date range:', e);
      }
    }
  }, [window.location.pathname]);

  const refreshData = useCallback(async (force = false) => {
    if (!force && !shouldRefreshData()) return;
  
    try {
      debouncedSetLoading(true);
      lastFetchRef.current = Date.now();
  
      if (!initialized) {
        await batchFetchEmissionsData();
        await fetchBaseYearData(true);
        setInitialized(true);
      } else {
        await Promise.all([
          batchFetchEmissionsData(),
          fetchBaseYearData(true)
        ]);
      }

      clearCache();
    } catch (error) {
      if (mountedRef.current) {
        setError(error.message);
      }
    } finally {
      if (mountedRef.current) {
        debouncedSetLoading(false);
      }
    }
  }, [
    shouldRefreshData,
    initialized,
    debouncedSetLoading,
    batchFetchEmissionsData,
    fetchBaseYearData,
    clearCache
  ]);

  useEffect(() => {
    mountedRef.current = true;

    const checkAuthAndInitialize = async () => {
      const token = localStorage.getItem('token');
      if (token && !initializationAttempted.current) {
        initializationAttempted.current = true;
        try {
          setLoading(true);
          await batchFetchEmissionsData();
          await fetchBaseYearData(true);
          setInitialized(true);
        } catch (error) {
          if (mountedRef.current) {
            console.error('Initialization error:', error);
            setError(error.message);
          }
        } finally {
          if (mountedRef.current) {
            setLoading(false);
          }
        }
      }
    };

    checkAuthAndInitialize();

    return () => {
      mountedRef.current = false;
      if (baseYearFetchTimeout.current) {
        clearTimeout(baseYearFetchTimeout.current);
      }
      if (authCheckInterval.current) {
        clearInterval(authCheckInterval.current);
      }
    };
  }, [batchFetchEmissionsData, fetchBaseYearData]);

  useEffect(() => {
    if (baseYearData?.current) {
      console.log('Base Year Data Structure:', {
        current: baseYearData.current,
        scope1: baseYearData.current.scope1,
        scope2: baseYearData.current.scope2,
        scope3: baseYearData.current.scope3,
        totals: {
          scope1: baseYearData.current.scope1_total,
          scope2: baseYearData.current.scope2_total,
          scope3: baseYearData.current.scope3_total,
          total: baseYearData.current.total_emissions
        }
      });
    }
  }, [baseYearData]);

  const contextValue = {
    emissionsData,
    baseYearData,
    dateRange,
    loading,
    error,
    initialized,
    earliestDate,
    latestDate,
    setDateRange: handleDateRangeChange,
    refreshData,
    calculateTotals: useCallback(() => calculateTotals(emissionsData), [emissionsData]),
    getTimeSeriesData: useCallback(() => processTimeSeriesData(emissionsData, baseYearData), 
      [emissionsData, baseYearData]),
    isDataLoaded: useCallback(() => initialized && !loading, [initialized, loading]),
    getTotalsByScope: useCallback(() => ({
      scope1: calculateTotals(emissionsData).scope1Total,
      scope2: calculateTotals(emissionsData).scope2Total,
      scope3: calculateTotals(emissionsData).scope3Total
    }), [emissionsData])
  };

  return (
    <UnifiedGHGContext.Provider value={contextValue}>
      {children}
    </UnifiedGHGContext.Provider>
  );
};

export default UnifiedGHGContext;
