// GHGBaseYearService.js
import apiClient from '../../api/axiosConfig';
import { safeParseEmissions } from './GHGUtils';

export const fetchBaseYearData = async (currentEmissionsData, force = false) => {
  try {
    console.log('Fetching base year data...');
    const response = await apiClient.get('/api/base-year');
    console.log('Base year data response:', response);

    // Ensure we have the expected data structure
    if (!response || (response.current === undefined && !Array.isArray(response.availableYears))) {
      console.error('Invalid base year data structure:', response);
      throw new Error('Invalid base year data received');
    }

    // Transform the data to match the expected format
    const baseYearData = {
      current: response.current ? {
        ...response.current,
        year: response.current.year.toString(),
        scope1_total: safeParseEmissions(response.current.scope1_total),
        scope2_total_location: safeParseEmissions(response.current.scope2_total_location),
        scope2_total_market: safeParseEmissions(response.current.scope2_total_market),
        scope3_total: safeParseEmissions(response.current.scope3_total),
        total_emissions_location: safeParseEmissions(response.current.total_emissions_location),
        total_emissions_market: safeParseEmissions(response.current.total_emissions_market),
        change: {
          value: "0.0",
          isIncrease: false
        }
      } : null,
      availableYears: Array.isArray(response.availableYears) ? response.availableYears.map(year => ({
        ...year,
        year: year.year.toString(),
        scope1_total: safeParseEmissions(year.scope1_total),
        scope2_total_location: safeParseEmissions(year.scope2_total_location),
        scope2_total_market: safeParseEmissions(year.scope2_total_market),
        scope3_total: safeParseEmissions(year.scope3_total),
        total_emissions_location: safeParseEmissions(year.total_emissions_location),
        total_emissions_market: safeParseEmissions(year.total_emissions_market)
      })) : []
    };

    // Calculate change percentage if we have current emissions data
    if (baseYearData.current && currentEmissionsData) {
      const processedData = processBaseYearData(baseYearData.current, currentEmissionsData);
      if (processedData) {
        baseYearData.current = processedData;
      }
    }

    console.log('Processed Base Year Data:', baseYearData);
    return baseYearData;
  } catch (error) {
    console.error('Error fetching base year data:', error);
    throw error;
  }
};

export const updateBaseYear = async (yearData) => {
  if (!yearData || !yearData.year) {
    throw new Error('Invalid year data provided');
  }

  try {
    console.log('Setting new base year:', yearData);
    const response = await apiClient.put('/api/base-year', { year: yearData.year });
    console.log('Update base year response:', response);

    if (!response) {
      console.error('Invalid response from server:', response);
      throw new Error('Invalid response from server');
    }

    // Transform the response to match expected format
    const transformedResponse = {
      ...response,
      year: response.year.toString(),
      scope1_total: safeParseEmissions(response.scope1_total),
      scope2_total_location: safeParseEmissions(response.scope2_total_location),
      scope2_total_market: safeParseEmissions(response.scope2_total_market),
      scope3_total: safeParseEmissions(response.scope3_total),
      total_emissions_location: safeParseEmissions(response.total_emissions_location),
      total_emissions_market: safeParseEmissions(response.total_emissions_market),
      change: {
        value: "0.0",
        isIncrease: false
      }
    };

    console.log('Transformed base year response:', transformedResponse);
    return transformedResponse;
  } catch (error) {
    console.error('Error updating base year:', error);
    throw error;
  }
};

export const processBaseYearData = (baseYearData, currentEmissionsData) => {
  if (!baseYearData || !currentEmissionsData) return null;

  const baseEmissions = parseFloat(baseYearData.total_emissions_market) || 0;
  const currentEmissions = parseFloat(currentEmissionsData.total_emissions_market) || 0;

  if (baseEmissions === 0) return baseYearData;

  const change = ((currentEmissions - baseEmissions) / baseEmissions) * 100;
  return {
    ...baseYearData,
    change: {
      value: Math.abs(change).toFixed(1),
      isIncrease: change > 0
    }
  };
};

export const validateBaseYearData = (data) => {
  if (!data) return false;
  return (
    typeof data.year === 'string' &&
    typeof data.scope1_total === 'string' &&
    typeof data.scope2_total_location === 'string' &&
    typeof data.scope2_total_market === 'string' &&
    typeof data.scope3_total === 'string' &&
    typeof data.total_emissions_location === 'string' &&
    typeof data.total_emissions_market === 'string'
  );
};

export const calculateYearEmissions = (yearData) => {
  if (!yearData) return null;
  
  const scope1 = parseFloat(yearData.scope1_total) || 0;
  const scope2Location = parseFloat(yearData.scope2_total_location) || 0;
  const scope2Market = parseFloat(yearData.scope2_total_market) || 0;
  const scope3 = parseFloat(yearData.scope3_total) || 0;

  return {
    location: (scope1 + scope2Location + scope3).toFixed(1),
    market: (scope1 + scope2Market + scope3).toFixed(1)
  };
};

export const getComparison = (baseYearData, currentEmissionsData) => {
  if (!baseYearData || !currentEmissionsData) return null;

  const baseEmissions = calculateYearEmissions(baseYearData);
  const currentEmissions = calculateYearEmissions(currentEmissionsData);

  if (!baseEmissions || !currentEmissions) return null;

  const locationChange = ((parseFloat(currentEmissions.location) - parseFloat(baseEmissions.location)) / parseFloat(baseEmissions.location)) * 100;
  const marketChange = ((parseFloat(currentEmissions.market) - parseFloat(baseEmissions.market)) / parseFloat(baseEmissions.market)) * 100;

  return {
    location: {
      value: Math.abs(locationChange).toFixed(1),
      isIncrease: locationChange > 0
    },
    market: {
      value: Math.abs(marketChange).toFixed(1),
      isIncrease: marketChange > 0
    }
  };
};
