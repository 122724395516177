// client/src/components/Settings/Scope3InitialAssessmentForm.js
import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Alert,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TimelineIcon from '@mui/icons-material/Timeline';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoIcon from '@mui/icons-material/Info';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import apiClient from '../../api/axiosConfig';

const FormContainer = styled('div')({
  padding: '32px',
  maxWidth: '1600px', // Increased from 1200px
  margin: '0 auto',
  width: '95%' // Added to ensure some margin on very wide screens
});

const SectionContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4), // Increased padding
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  backgroundColor: '#ffffff',
  '& .MuiTableContainer-root': {
    overflowX: 'auto'
  }
}));

const SectionHeading = styled(Typography)(({ theme }) => ({
  fontSize: '1.075rem',
  fontWeight: 500,
  color: '#4B5563',
  marginBottom: theme.spacing(3),
  paddingBottom: '0.5rem',
  borderBottom: 'none'
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiCardContent-root': {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#ffffff',
  '& .MuiStepLabel-root .Mui-active': {
    color: '#008080'
  },
  '& .MuiStepLabel-root .Mui-completed': {
    color: '#008080'
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#008080',
  color: 'white',
  padding: '12px 16px',
  whiteSpace: 'nowrap',
  '&.MuiTableCell-head': {
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    letterSpacing: '0.05em'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .MuiTableCell-root': {
    padding: '12px 16px',
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}));

// Add new constant for categories
const SCOPE3_CATEGORIES = [
  {
    id: 1,
    name: 'Purchased Goods and Services',
    description: 'All upstream emissions from production of products and services',
    recommended_method: 'Spend-based → Supplier-specific',
    data_needed: [
      'Purchase records',
      'Supplier emissions data',
      'Product-level data'
    ],
    example: 'Raw materials, packaging, office supplies'
  },
  {
    id: 2,
    name: 'Capital Goods',
    description: 'Emissions from production of capital goods',
    recommended_method: 'Spend-based',
    data_needed: [
      'Capital expenditure data',
      'Asset specifications',
      'Equipment lifecycle data'
    ],
    example: 'Buildings, machinery, vehicles'
  },
  {
    id: 3,
    name: 'Fuel and Energy Activities',
    description: 'Emissions related to fuel and energy not in Scope 1 or 2',
    recommended_method: 'Average-data',
    data_needed: [
      'Energy consumption data',
      'Fuel usage records',
      'Grid emission factors'
    ],
    example: 'Transmission losses, fuel extraction'
  },
  {
    id: 4,
    name: 'Transportation and Distribution (Upstream)',
    description: 'Transportation of purchased products',
    recommended_method: 'Distance-based',
    data_needed: [
      'Shipping records',
      'Transport modes',
      'Distance traveled'
    ],
    example: 'Freight, warehousing, logistics'
  },
  {
    id: 5,
    name: 'Waste Generated in Operations',
    description: 'Disposal and treatment of waste',
    recommended_method: 'Waste-type-specific',
    data_needed: [
      'Waste quantity by type',
      'Disposal methods',
      'Treatment data'
    ],
    example: 'Landfill, recycling, composting'
  },
  {
    id: 6,
    name: 'Business Travel',
    description: 'Employee business-related travel',
    recommended_method: 'Distance-based',
    data_needed: [
      'Travel records',
      'Flight data',
      'Hotel stays'
    ],
    example: 'Flights, hotel stays, rental cars'
  },
  {
    id: 7,
    name: 'Employee Commuting',
    description: 'Employee travel between home and work',
    recommended_method: 'Distance-based',
    data_needed: [
      'Employee survey data',
      'Commute distances',
      'Transport modes'
    ],
    example: 'Car, public transport, remote work'
  },
  {
    id: 8,
    name: 'Leased Assets (Upstream)',
    description: 'Operation of leased assets',
    recommended_method: 'Asset-specific',
    data_needed: [
      'Asset energy use',
      'Operational data',
      'Lease agreements'
    ],
    example: 'Leased vehicles, buildings, equipment'
  },
  {
    id: 9,
    name: 'Transportation and Distribution (Downstream)',
    description: 'Transportation of sold products',
    recommended_method: 'Distance-based',
    data_needed: [
      'Distribution data',
      'Customer locations',
      'Transport modes'
    ],
    example: 'Product shipping to customers'
  },
  {
    id: 10,
    name: 'Processing of Sold Products',
    description: 'Processing of intermediate products',
    recommended_method: 'Average-data',
    data_needed: [
      'Processing data',
      'Energy requirements',
      'Product specifications'
    ],
    example: 'Manufacturing of components'
  },
  {
    id: 11,
    name: 'Use of Sold Products',
    description: 'End-use of goods and services',
    recommended_method: 'Energy-based',
    data_needed: [
      'Product energy use',
      'Lifetime assumptions',
      'Usage patterns'
    ],
    example: 'Product energy consumption'
  },
  {
    id: 12,
    name: 'End-of-Life Treatment',
    description: 'Disposal of sold products',
    recommended_method: 'Waste-type-specific',
    data_needed: [
      'Product composition',
      'Disposal patterns',
      'Recycling rates'
    ],
    example: 'Product disposal and recycling'
  },
  {
    id: 13,
    name: 'Leased Assets (Downstream)',
    description: 'Operation of assets leased to others',
    recommended_method: 'Asset-specific',
    data_needed: [
      'Asset performance data',
      'Operational records',
      'Energy consumption'
    ],
    example: 'Leased equipment operation'
  },
  {
    id: 14,
    name: 'Franchises',
    description: 'Operation of franchises',
    recommended_method: 'Franchise-specific',
    data_needed: [
      'Franchise energy use',
      'Operational data',
      'Activity records'
    ],
    example: 'Franchise operations'
  },
  {
    id: 15,
    name: 'Investments',
    description: 'Operation of investments',
    recommended_method: 'Investment-specific',
    data_needed: [
      'Investment data',
      'Project information',
      'Company records'
    ],
    example: 'Investment portfolio emissions'
  }
];

const CategoryDetails = ({ category }) => {
  const selectedCategory = SCOPE3_CATEGORIES.find(c => c.id === category);
  
  if (!selectedCategory) return null;

  return (
    <Paper sx={{ p: 3, bgcolor: 'grey.50' }}>
      <Typography variant="h6" sx={{ color: '#008080' }} gutterBottom>
        Category {category}: {selectedCategory.name}
      </Typography>
      
      {/* Description */}
      <Box mb={3}>
        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
          Description
        </Typography>
        <Typography variant="body1">
          {selectedCategory.description}
        </Typography>
      </Box>

      {/* Examples */}
      <Box mb={3}>
        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
          Examples & Activities
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, bgcolor: 'white' }}>
              <Typography variant="subtitle2" gutterBottom>
                Common Examples
              </Typography>
              <List dense>
                {category === 1 && [
                  { primary: "Raw materials", secondary: "Steel, plastics, chemicals" },
                  { primary: "Packaging materials", secondary: "Cardboard, plastic wrap, pallets" },
                  { primary: "Office supplies", secondary: "Paper, computers, furniture" },
                  { primary: "Services", secondary: "Consulting, legal, accounting" }
                ].map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                    </ListItemIcon>
                    <ListItemText primary={item.primary} secondary={item.secondary} />
                  </ListItem>
                ))}
                {category === 2 && [
                  { primary: "Manufacturing equipment", secondary: "Production lines, machinery" },
                  { primary: "Buildings", secondary: "Factories, warehouses, offices" },
                  { primary: "Vehicles", secondary: "Company cars, trucks, forklifts" },
                  { primary: "IT infrastructure", secondary: "Servers, network equipment" }
                ].map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                    </ListItemIcon>
                    <ListItemText primary={item.primary} secondary={item.secondary} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, bgcolor: 'white' }}>
              <Typography variant="subtitle2" gutterBottom>
                Key Considerations
              </Typography>
              <List dense>
                {category === 1 && [
                  "Consider both goods and services",
                  "Include all upstream emissions",
                  "Account for primary and secondary suppliers",
                  "Consider geographical differences"
                ].map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
                {category === 2 && [
                  "Consider full lifecycle emissions",
                  "Include installation and setup",
                  "Account for maintenance and upgrades",
                  "Consider end-of-life disposal"
                ].map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Calculation Methods */}
      <Box mb={3}>
        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
          Calculation Methods
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, bgcolor: 'white' }}>
              <Typography variant="subtitle2" gutterBottom>
                Recommended Approach
              </Typography>
              <Typography variant="body2" paragraph>
                {selectedCategory.recommended_method}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Required Data:
              </Typography>
              <List dense>
                {selectedCategory.data_needed.map((data, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon sx={{ color: '#008080' }} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={data} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, bgcolor: 'white' }}>
              <Typography variant="subtitle2" gutterBottom>
                Data Collection Tips
              </Typography>
              <Alert severity="info" sx={{ mb: 2 }}>
                {category === 1 
                  ? "Start with procurement data and gradually work towards supplier-specific information"
                  : category === 2 
                  ? "Begin with capital expenditure records and asset specifications"
                  : "Follow category-specific data collection guidance"}
              </Alert>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Key Departments" 
                    secondary={
                      category === 1 ? "Procurement, Finance, Supplier Management" :
                      category === 2 ? "Facilities, IT, Fleet Management" :
                      "Relevant departments"
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Data Sources" 
                    secondary={
                      category === 1 ? "Purchase orders, supplier invoices, contracts" :
                      category === 2 ? "Asset registers, purchase records, specifications" :
                      "Relevant data sources"
                    }
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const Scope3Guidance = () => {
  const [showAssessment, setShowAssessment] = React.useState(false);
  const [showGuide, setShowGuide] = React.useState(false);
  const [showResults, setShowResults] = React.useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [assessments, setAssessments] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [selectedAssessment, setSelectedAssessment] = React.useState(null);
  const [showDetailsDialog, setShowDetailsDialog] = React.useState(false);
  const [formData, setFormData] = React.useState({
        category: '',
        relevance: '',
        dataAvailability: '',
        spendData: '',
        activityData: '',
        supplierData: '',
        comments: '',
        date: new Date().toISOString().split('T')[0]
      });

  // Add handler functions
  const handleViewDetails = (assessment) => {
    setSelectedAssessment(assessment);
    setShowDetailsDialog(true);
  };

  const handleCloseDetails = () => {
    setSelectedAssessment(null);
    setShowDetailsDialog(false);
  };

  const handleEditAssessment = (assessment) => {
    setFormData({
      category: assessment.category.toString(),
      relevance: assessment.relevance,
      spendData: assessment.spendData,
      activityData: assessment.activityData,
      supplierData: assessment.supplierData,
      comments: assessment.comments,
      date: assessment.date
    });
    setShowAssessment(true);
    setShowResults(false);
    setShowDetailsDialog(false);
  };

  const handleDeleteAssessment = async (assessmentId) => {
    if (window.confirm('Are you sure you want to delete this assessment?')) {
      try {
        setLoading(true);
        await apiClient.delete(`/api/scope3-initial-assessment/${assessmentId}`);
        setAssessments(prev => prev.filter(a => a._id !== assessmentId));
        setShowDetailsDialog(false);
      } catch (err) {
        console.error('Error deleting assessment:', err);
        setError('Failed to delete assessment');
      } finally {
        setLoading(false);
      }
    }
  };

  // Load assessments from API on component mount
  React.useEffect(() => {
    const fetchAssessments = async () => {
      try {
        setLoading(true);
        console.log('Fetching assessments...');
        const data = await apiClient.get('/api/scope3-initial-assessment');
        console.log('Raw API Response:', data);

        // Since the interceptor returns response.data directly, we can access assessments directly
        const assessmentsData = data.assessments || [];

        console.log('Assessments data:', assessmentsData);

        if (!assessmentsData || assessmentsData.length === 0) {
          console.log('No assessments data found');
          setAssessments([]);
          return;
        }

        // Format the assessments data
        const formattedAssessments = assessmentsData
          .filter(assessment => assessment && assessment.category) // Filter out any invalid data
          .map(assessment => ({
            _id: assessment._id,
            category: parseInt(assessment.category),
            categoryName: SCOPE3_CATEGORIES.find(c => c.id === parseInt(assessment.category))?.name || 'Unknown Category',
            relevance: assessment.relevance,
            spendData: assessment.spendData,
            activityData: assessment.activityData,
            supplierData: assessment.supplierData,
            comments: assessment.comments || '',
            date: assessment.date,
            status: assessment.status || 'active'
          }));

        console.log('Formatted assessments:', formattedAssessments);
        setAssessments(formattedAssessments);
        setError(null);
      } catch (err) {
        console.error('Error fetching assessments:', err);
        setError('Failed to load assessments. Please try refreshing the page.');
        setAssessments([]);
      } finally {
        setLoading(false);
      }
    };

    // Call the fetch function
    fetchAssessments();
    
    // Set up an interval to periodically refresh the data every 30 seconds
    const refreshInterval = setInterval(fetchAssessments, 30000);
    
    // Cleanup interval on component unmount
    return () => clearInterval(refreshInterval);
  }, []); // Empty dependency array means this runs once on mount

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleStartAssessment = () => {
    setShowGuide(false);
    setShowResults(true);
  };

  const handleStartNewAssessment = () => {
    setShowAssessment(true);
    setShowResults(false);
  };

  const handleSubmit = async () => {
    try {
      const selectedCategory = SCOPE3_CATEGORIES.find(c => c.id === parseInt(formData.category));
      if (!selectedCategory) {
        setError('Please select a category');
        return;
      }

      // Check if category already exists
      const categoryExists = assessments.some(
        assessment => assessment.category === parseInt(formData.category) && assessment.status === 'active'
      );

      if (categoryExists) {
        setError('This category has already been assessed. Please select a different category.');
        return;
      }

      // Validate required fields
      if (!formData.relevance || !formData.spendData || !formData.activityData || !formData.supplierData) {
        setError('Please fill in all required fields');
        return;
      }

      // Format the assessment data according to the API requirements
      const assessmentData = {
        category: parseInt(formData.category),
        categoryName: selectedCategory.name,
        relevance: formData.relevance,
        spendData: formData.spendData,
        activityData: formData.activityData,
        supplierData: formData.supplierData,
        comments: formData.comments || '',
        date: new Date().toISOString(),
        status: 'active',
        lastUpdated: new Date().toISOString()
      };
      
      // Show loading state
      setLoading(true);
      setError(null);

      console.log('Submitting assessment data:', assessmentData);
      const response = await apiClient.post('/api/scope3-initial-assessment', assessmentData);
      console.log('Submit response:', response);
      console.log('Submit response data:', response.data);
      
      let savedAssessment;
      if (response.data?.assessment) {
        console.log('Using assessment from response');
        savedAssessment = response.data.assessment;
      } else if (response.data && typeof response.data === 'object') {
        console.log('Using direct response data');
        savedAssessment = response.data;
      } else {
        console.log('Using submitted data as fallback');
        savedAssessment = assessmentData;
      }

      console.log('Saved assessment:', savedAssessment);
      
      // Add the new assessment to the state
      const formattedAssessment = {
        ...savedAssessment,
        _id: savedAssessment._id || savedAssessment.id || String(new Date().getTime()),
        category: parseInt(savedAssessment.category) || parseInt(formData.category),
        categoryName: selectedCategory.name,
        relevance: savedAssessment.relevance || formData.relevance,
        spendData: savedAssessment.spendData || formData.spendData,
        activityData: savedAssessment.activityData || formData.activityData,
        supplierData: savedAssessment.supplierData || formData.supplierData,
        comments: savedAssessment.comments || formData.comments || '',
        date: savedAssessment.date || new Date().toISOString(),
        status: savedAssessment.status || 'active'
      };

      console.log('Formatted assessment to add:', formattedAssessment);
      setAssessments(prev => [...prev, formattedAssessment]);
      
      // Reset form and show results
      setShowResults(true);
      setShowAssessment(false);
      setActiveStep(0);
      setFormData({
        category: '',
        relevance: '',
        dataAvailability: '',
        spendData: '',
        activityData: '',
        supplierData: '',
        comments: '',
        date: new Date().toISOString().split('T')[0]
      });
    } catch (err) {
      console.error('Error saving assessment:', err);
      console.error('Error details:', {
        message: err.message,
        response: err.response,
        stack: err.stack
      });
      const errorMessage = err.response?.data?.message || err.message || 'Failed to save assessment. Please try again.';
      setError(errorMessage);
      // Keep the form open when there's an error
      setShowResults(false);
      setShowAssessment(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClearAssessments = async () => {
    if (window.confirm('Are you sure you want to clear all assessments? This cannot be undone.')) {
      try {
        setLoading(true);
        await Promise.all(assessments.map(assessment => 
          apiClient.delete(`/api/scope3-initial-assessment/${assessment._id}`)
        ));
      setAssessments([]);
      } catch (err) {
        console.error('Error clearing assessments:', err);
        setError(err.response?.data?.message || 'Failed to clear assessments');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleViewGuide = () => {
    setShowGuide(true);
    setShowResults(false);
    setShowAssessment(false);
  };

  const steps = [
    'Select Category',
    'Assess Relevance',
    'Data Availability',
    'Next Steps'
  ];

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Select Scope 3 Category</InputLabel>
                <Select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  label="Select Scope 3 Category"
                  sx={{
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#008080'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#008080'
                    }
                  }}
                >
                  {SCOPE3_CATEGORIES.map((category) => {
                    const isAssessed = assessments.some(
                      assessment => assessment.category === category.id && assessment.status === 'active'
                    );
                    return (
                      <MenuItem 
                        key={category.id} 
                        value={category.id}
                        disabled={isAssessed}
                        sx={isAssessed ? {
                          opacity: 0.6,
                          '&:hover': {
                            cursor: 'not-allowed'
                          }
                        } : {}}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                          <Typography>Category {category.id}: {category.name}</Typography>
                          {isAssessed && (
                            <Typography variant="caption" sx={{ color: 'success.main', ml: 2 }}>
                              Already Assessed
                            </Typography>
                          )}
                        </Box>
                    </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {formData.category && (
              <Grid item xs={12}>
                <CategoryDetails category={formData.category} />
              </Grid>
            )}
          </Grid>
        );

      case 1:
        const selectedCategory = SCOPE3_CATEGORIES.find(c => c.id === formData.category);
        return (
          <Grid container spacing={3}>
            {/* Selected Category Summary */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, mb: 3, bgcolor: '#008080' }}>
                <Typography variant="h6" color="white">
                  Selected: Category {formData.category} - {selectedCategory?.name}
                </Typography>
                <Typography variant="body2" color="white">
                  {selectedCategory?.description}
                </Typography>
              </Paper>
            </Grid>

            {/* Relevance Assessment */}
            <Grid item xs={12} md={6}>
              <FormControl component="fieldset">
                <Typography variant="subtitle1" gutterBottom>
                  How important is this category for your company?
                </Typography>
                <Alert severity="info" sx={{ mb: 2 }}>
                  Select the option that best describes how much this category affects your business
                </Alert>
                <RadioGroup
                  name="relevance"
                  value={formData.relevance}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="high" 
                    control={<Radio sx={{
                      '&.Mui-checked': {
                        color: '#008080'
                      }
                    }} />} 
                    label={
                      <Box>
                        <Typography variant="body1">Very Important</Typography>
                        <Typography variant="caption" color="text.secondary">
                          This is a major part of our business activities
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel 
                    value="medium" 
                    control={<Radio sx={{
                      '&.Mui-checked': {
                        color: '#008080'
                      }
                    }} />} 
                    label={
                      <Box>
                        <Typography variant="body1">Somewhat Important</Typography>
                        <Typography variant="caption" color="text.secondary">
                          This affects our business but isn't a major part
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel 
                    value="low" 
                    control={<Radio sx={{
                      '&.Mui-checked': {
                        color: '#008080'
                      }
                    }} />} 
                    label={
                      <Box>
                        <Typography variant="body1">Less Important</Typography>
                        <Typography variant="caption" color="text.secondary">
                          This has minimal impact on our business
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel 
                    value="unknown" 
                    control={<Radio sx={{
                      '&.Mui-checked': {
                        color: '#008080'
                      }
                    }} />} 
                    label={
                      <Box>
                        <Typography variant="body1">Not Sure Yet</Typography>
                        <Typography variant="caption" color="text.secondary">
                          We need to look into this more
                        </Typography>
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Category Examples */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Examples for {selectedCategory?.name}
                </Typography>
                <List dense>
                  {formData.category === 1 && [
                    { primary: "Raw Materials", secondary: "Steel, plastics, chemicals used in production" },
                    { primary: "Packaging", secondary: "Product packaging, shipping materials" },
                    { primary: "Office Supplies", secondary: "Paper, computers, furniture" },
                    { primary: "Services", secondary: "Consulting, cleaning, maintenance" }
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={item.primary} secondary={item.secondary} />
                    </ListItem>
                  ))}
                  {formData.category === 2 && [
                    { primary: "Manufacturing Equipment", secondary: "Production machinery, assembly lines" },
                    { primary: "Buildings", secondary: "Factories, warehouses, offices" },
                    { primary: "Vehicles", secondary: "Company cars, delivery trucks" },
                    { primary: "IT Infrastructure", secondary: "Servers, networking equipment" }
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={item.primary} secondary={item.secondary} />
                    </ListItem>
                  ))}
                  {formData.category === 3 && [
                    { primary: "Fuel Production", secondary: "Extraction and processing of fuels" },
                    { primary: "Transmission Losses", secondary: "Electricity grid losses" },
                    { primary: "Energy Distribution", secondary: "Pipeline operations, fuel transport" }
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={item.primary} secondary={item.secondary} />
                    </ListItem>
                  ))}
                  {/* Add similar blocks for other categories */}
                </List>

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle2" color="primary" gutterBottom>
                  When is this "Very Important"?
                </Typography>
                <List dense>
                  {formData.category === 1 && [
                    "Large volume of purchased materials",
                    "High-value procurement activities",
                    "Critical to core business operations",
                    "Many suppliers or complex supply chain"
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                  {formData.category === 2 && [
                    "Recent major equipment purchases",
                    "Significant infrastructure investments",
                    "Regular fleet or equipment upgrades",
                    "Large facility construction projects"
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                  {formData.category === 3 && [
                    "Energy-intensive operations",
                    "Large fuel consumption",
                    "Significant electricity usage",
                    "Multiple facility locations"
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                  {/* Add similar blocks for other categories */}
                </List>
              </Paper>
            </Grid>
          </Grid>
        );

      case 2:
        const selectedCategoryData = SCOPE3_CATEGORIES.find(c => c.id === formData.category);
        return (
          <Grid container spacing={3}>
            {/* Selected Category Summary */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, mb: 3, bgcolor: '#008080' }}>
                <Typography variant="h6" color="white">
                  Selected: Category {formData.category} - {selectedCategoryData?.name}
                </Typography>
                <Typography variant="body2" color="white">
                  {selectedCategoryData?.description}
                </Typography>
              </Paper>
            </Grid>

            {/* Data Availability Form */}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                What information do you currently have?
              </Typography>
              <Alert severity="info" sx={{ mb: 3 }}>
                For each type of data below, tell us how much information you can access right now
              </Alert>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Money Spent (Purchase Records)</InputLabel>
                    <Select
                      name="spendData"
                      value={formData.spendData}
                      onChange={handleChange}
                      label="Money Spent (Purchase Records)"
                      sx={{
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#008080'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#008080'
                        }
                      }}
                    >
                      <MenuItem value="complete">
                        <Box>
                          <Typography variant="body2">We have all the records</Typography>
                          <Typography variant="caption" color="text.secondary">
                            Complete purchase/financial data available
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value="partial">
                        <Box>
                          <Typography variant="body2">We have some records</Typography>
                          <Typography variant="caption" color="text.secondary">
                            Only partial or incomplete data available
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value="none">
                        <Box>
                          <Typography variant="body2">No records yet</Typography>
                          <Typography variant="caption" color="text.secondary">
                            Need to start collecting this information
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Activity Information (What Was Done)</InputLabel>
                    <Select
                      name="activityData"
                      value={formData.activityData}
                      onChange={handleChange}
                      label="Activity Information (What Was Done)"
                      sx={{
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#008080'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#008080'
                        }
                      }}
                    >
                      <MenuItem value="complete">
                        <Box>
                          <Typography variant="body2">We track everything</Typography>
                          <Typography variant="caption" color="text.secondary">
                            Detailed records of all activities available
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value="partial">
                        <Box>
                          <Typography variant="body2">We track some things</Typography>
                          <Typography variant="caption" color="text.secondary">
                            Have basic records but some gaps exist
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value="none">
                        <Box>
                          <Typography variant="body2">Not tracking yet</Typography>
                          <Typography variant="caption" color="text.secondary">
                            Need to start recording activities
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Supplier Information</InputLabel>
                    <Select
                      name="supplierData"
                      value={formData.supplierData}
                      onChange={handleChange}
                      label="Supplier Information"
                      sx={{
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#008080'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#008080'
                        }
                      }}
                    >
                      <MenuItem value="complete">
                        <Box>
                          <Typography variant="body2">Full supplier data</Typography>
                          <Typography variant="caption" color="text.secondary">
                            We have detailed information from our suppliers
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value="partial">
                        <Box>
                          <Typography variant="body2">Some supplier data</Typography>
                          <Typography variant="caption" color="text.secondary">
                            Have information from some suppliers
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value="none">
                        <Box>
                          <Typography variant="body2">No supplier data</Typography>
                          <Typography variant="caption" color="text.secondary">
                            Need to start collecting supplier information
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Required Data Examples */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Required Data for {selectedCategoryData?.name}
                </Typography>
                <Alert severity="info" sx={{ mb: 2 }}>
                  Here are the typical data points needed for this category
                </Alert>
                <List dense>
                  {selectedCategoryData?.data_needed.map((data, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={data} />
                    </ListItem>
                  ))}
                </List>

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle2" color="primary" gutterBottom>
                  Data Collection Tips
                </Typography>
                <List dense>
                  {formData.category === 1 && [
                    { primary: "Financial Records", secondary: "Purchase orders, invoices, contracts" },
                    { primary: "Supplier Data", secondary: "Emissions data, product specifications" },
                    { primary: "Activity Data", secondary: "Quantities purchased, usage records" }
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={item.primary} secondary={item.secondary} />
                    </ListItem>
                  ))}
                  {formData.category === 2 && [
                    { primary: "Asset Records", secondary: "Equipment specifications, purchase history" },
                    { primary: "Facility Data", secondary: "Building information, energy usage" },
                    { primary: "Maintenance Records", secondary: "Service history, upgrades" }
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={item.primary} secondary={item.secondary} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        );

      case 3:
        const selectedCategoryNext = SCOPE3_CATEGORIES.find(c => c.id === formData.category);
        return (
          <Grid container spacing={3}>
            {/* Selected Category Summary */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, mb: 3, bgcolor: '#008080' }}>
                <Typography variant="h6" color="white">
                  Selected: Category {formData.category} - {selectedCategoryNext?.name}
                </Typography>
                <Typography variant="body2" color="white">
                  {selectedCategoryNext?.description}
                </Typography>
              </Paper>
            </Grid>

            {/* Recommendations */}
            <Grid item xs={12} md={6}>
              <Alert severity="success" sx={{ mb: 2 }}>
                Based on your responses, here are the recommended next steps for assessing this category.
              </Alert>
              <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Recommended Approach
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText 
                      primary={formData.spendData === 'complete' ? 
                        "Start with Spend-Based Analysis" : 
                        "Begin Data Collection"}
                      secondary={formData.spendData === 'complete' ? 
                        "Use available financial data to calculate initial estimates" :
                        "Focus on gathering key financial and activity data"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Identify Data Gaps"
                      secondary="Plan for collecting missing information"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Set Timeline"
                      secondary="Establish milestones for improvement"
                    />
                  </ListItem>
                </List>

              <TextField
                fullWidth
                multiline
                rows={4}
                name="comments"
                label="Additional Comments"
                value={formData.comments}
                onChange={handleChange}
                placeholder="Add any notes or specific considerations for this category"
                sx={{ 
                  mt: 2,
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#008080'
                    },
                    '&:hover fieldset': {
                      borderColor: '#008080'
                    }
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#008080'
                  }
                }}
                />
              </Paper>
            </Grid>

            {/* Category-Specific Guidance */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Implementation Guide
                </Typography>
                <Alert severity="info" sx={{ mb: 2 }}>
                  Specific guidance for implementing {selectedCategoryNext?.name} assessment
                </Alert>

                <Typography variant="subtitle2" color="primary" gutterBottom>
                  Recommended Method
                </Typography>
                <Typography variant="body2" paragraph>
                  {selectedCategoryNext?.recommended_method}
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle2" color="primary" gutterBottom>
                  Key Steps
                </Typography>
                <List dense>
                  {formData.category === 1 && [
                    { primary: "Gather Financial Data", secondary: "Collect all purchase records and spending data" },
                    { primary: "Engage Suppliers", secondary: "Request emissions data from key suppliers" },
                    { primary: "Apply Emission Factors", secondary: "Use appropriate factors for spend-based calculations" }
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={item.primary} secondary={item.secondary} />
                    </ListItem>
                  ))}
                  {formData.category === 2 && [
                    { primary: "Asset Inventory", secondary: "Create detailed list of capital goods" },
                    { primary: "Collect Specifications", secondary: "Gather technical details and lifecycle data" },
                    { primary: "Calculate Emissions", secondary: "Apply appropriate emission factors to purchases" }
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={item.primary} secondary={item.secondary} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  const AssessmentResults = () => (
    <FormContainer>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      ) : (
        <>
          <SectionContainer>
            <Box mb={4}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <div>
                  <SectionHeading variant="h4" gutterBottom>
                    Scope 3 Assessment Results
                  </SectionHeading>
                  <Typography variant="subtitle1" color="text.secondary">
                    Overview of your value chain emissions assessment progress
                  </Typography>
                </div>
                <Box sx={{ display: 'flex', gap: 2 }}>
  <Button
    variant="outlined"
    startIcon={<AssessmentIcon />}
    onClick={handleViewGuide}
    size="large"
    sx={{ 
      color: '#008080',
      borderColor: '#008080',
      '&:hover': {
        borderColor: '#006666',
        backgroundColor: 'rgba(0, 128, 128, 0.04)'
      }
    }}
  >
    View Guide
  </Button>
</Box>
              </Box>
            </Box>

            {/* Action Buttons Section */}
            <Box sx={{ bgcolor: 'grey.50', p: 3, borderRadius: 1, mb: 4 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    Ready to assess another category?
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Continue building your Scope 3 inventory by assessing additional categories
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: { md: 'flex-end' } }}>
                  <Button 
                    variant="contained" 
                    onClick={handleStartNewAssessment}
                    startIcon={<BarChartIcon />}
                    size="large"
                    sx={{ 
                      backgroundColor: '#008080',
                      '&:hover': {
                        backgroundColor: '#006666'
                      }
                    }}
                  >
                    Start New Assessment
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* Results Table */}
            <TableContainer component={Paper} sx={{ boxShadow: 1, mb: 4 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Assessment Date</StyledTableCell>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell>Relevance</StyledTableCell>
                    <StyledTableCell>Data Availability Status</StyledTableCell>
                    <StyledTableCell>Next Steps</StyledTableCell>
                    <StyledTableCell>Notes</StyledTableCell>
                    <StyledTableCell align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assessments.map((assessment) => (
                    <StyledTableRow key={assessment._id}>
                      <TableCell>
                        <Typography variant="body2">
                          {new Date(assessment.date).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 500 }}>
                            {assessment.categoryName}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            Category {assessment.category}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ 
                          display: 'inline-block',
                          px: 1.5,
                          py: 0.75,
                          borderRadius: 1,
                          bgcolor: assessment.relevance === 'high' ? 'error.50' :
                                  assessment.relevance === 'medium' ? 'warning.50' :
                                  assessment.relevance === 'low' ? 'success.50' : 'grey.50',
                          color: assessment.relevance === 'high' ? 'error.700' :
                                 assessment.relevance === 'medium' ? 'warning.700' :
                                 assessment.relevance === 'low' ? 'success.700' : 'grey.700',
                          border: 1,
                          borderColor: assessment.relevance === 'high' ? 'error.200' :
                                      assessment.relevance === 'medium' ? 'warning.200' :
                                      assessment.relevance === 'low' ? 'success.200' : 'grey.200'
                        }}>
                          <Typography variant="body2" fontWeight="500">
                            {assessment.relevance === 'high' ? 'High Priority' :
                             assessment.relevance === 'medium' ? 'Medium Priority' :
                             assessment.relevance === 'low' ? 'Low Priority' : 'To Be Determined'}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Box display="flex" alignItems="center" mb={1}>
                            <Typography variant="caption" sx={{ width: 100, color: 'text.secondary' }}>
                              Spend Data:
                            </Typography>
                            <Box sx={{ 
                              px: 1.5,
                              py: 0.5,
                              borderRadius: 1,
                              bgcolor: assessment.spendData === 'complete' ? 'success.50' :
                                      assessment.spendData === 'partial' ? 'warning.50' : 'error.50',
                              color: assessment.spendData === 'complete' ? 'success.700' :
                                     assessment.spendData === 'partial' ? 'warning.700' : 'error.700',
                              border: 1,
                              borderColor: assessment.spendData === 'complete' ? 'success.200' :
                                          assessment.spendData === 'partial' ? 'warning.200' : 'error.200'
                            }}>
                              <Typography variant="caption" fontWeight="500">
                                {assessment.spendData === 'complete' ? 'Complete' :
                                 assessment.spendData === 'partial' ? 'Partial' : 'Not Available'}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="center" mb={1}>
                            <Typography variant="caption" sx={{ width: 100, color: 'text.secondary' }}>
                              Activity Data:
                            </Typography>
                            <Box sx={{ 
                              px: 1.5,
                              py: 0.5,
                              borderRadius: 1,
                              bgcolor: assessment.activityData === 'complete' ? 'success.50' :
                                      assessment.activityData === 'partial' ? 'warning.50' : 'error.50',
                              color: assessment.activityData === 'complete' ? 'success.700' :
                                     assessment.activityData === 'partial' ? 'warning.700' : 'error.700',
                              border: 1,
                              borderColor: assessment.activityData === 'complete' ? 'success.200' :
                                          assessment.activityData === 'partial' ? 'warning.200' : 'error.200'
                            }}>
                              <Typography variant="caption" fontWeight="500">
                                {assessment.activityData === 'complete' ? 'Complete' :
                                 assessment.activityData === 'partial' ? 'Partial' : 'Not Available'}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography variant="caption" sx={{ width: 100, color: 'text.secondary' }}>
                              Supplier Data:
                            </Typography>
                            <Box sx={{ 
                              px: 1.5,
                              py: 0.5,
                              borderRadius: 1,
                              bgcolor: assessment.supplierData === 'complete' ? 'success.50' :
                                      assessment.supplierData === 'partial' ? 'warning.50' : 'error.50',
                              color: assessment.supplierData === 'complete' ? 'success.700' :
                                     assessment.supplierData === 'partial' ? 'warning.700' : 'error.700',
                              border: 1,
                              borderColor: assessment.supplierData === 'complete' ? 'success.200' :
                                          assessment.supplierData === 'partial' ? 'warning.200' : 'error.200'
                            }}>
                              <Typography variant="caption" fontWeight="500">
                                {assessment.supplierData === 'complete' ? 'Complete' :
                                 assessment.supplierData === 'partial' ? 'Partial' : 'Not Available'}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 500 }}>
                            {assessment.spendData === 'complete' ? 
                              "Start with Spend-Based Analysis" : 
                              "Begin Data Collection"}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {assessment.spendData === 'complete' ? 
                              "Use available financial data" : 
                              "Focus on gathering key data points"}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body2" sx={{ 
                          maxWidth: 200,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            color: 'text.secondary'
                        }}>
                          {assessment.comments || "No additional notes"}
                        </Typography>
                        {assessment.comments && (
                          <Tooltip title={assessment.comments}>
                              <IconButton size="small" sx={{ color: 'primary.main' }}>
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleViewDetails(assessment)}
                          startIcon={<VisibilityIcon />}
                          sx={{ 
                            color: '#008080',
                            borderColor: '#008080',
                            '&:hover': {
                              borderColor: '#006666',
                              backgroundColor: 'rgba(0, 128, 128, 0.04)'
                            }
                          }}
                        >
                          Details
                        </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleEditAssessment(assessment)}
                            startIcon={<EditIcon />}
                            sx={{ 
                              color: '#008080',
                              borderColor: '#008080',
                              '&:hover': {
                                borderColor: '#006666',
                                backgroundColor: 'rgba(0, 128, 128, 0.04)'
                              }
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleDeleteAssessment(assessment._id)}
                            startIcon={<DeleteIcon />}
                            color="error"
                          >
                            Delete
                          </Button>
                        </Box>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SectionContainer>

          {assessments.length === 0 && (
            <SectionContainer sx={{ textAlign: 'center', py: 6 }}>
              <AssessmentIcon sx={{ fontSize: 60, color: 'action.disabled', mb: 2 }} />
              <Typography variant="h6" color="text.secondary" gutterBottom>
                No Assessments Yet
              </Typography>
              <Typography variant="body2" color="text.secondary" mb={3}>
                Start by assessing your first Scope 3 category to track your progress
              </Typography>
              <Button
                variant="contained"
                onClick={handleStartNewAssessment}
                startIcon={<BarChartIcon />}
                size="large"
                sx={{ 
                  backgroundColor: '#008080',
                  '&:hover': {
                    backgroundColor: '#006666'
                  }
                }}
              >
                Start First Assessment
              </Button>
            </SectionContainer>
          )}
        </>
      )}
    </FormContainer>
  );

  if (showResults) {
    return <AssessmentResults />;
  }

  if (showAssessment) {
    return (
      <FormContainer>
        <SectionContainer>
          <SectionHeading variant="h4" gutterBottom>
            Scope 3 Category Assessment
          </SectionHeading>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Complete the following steps to assess your Scope 3 emissions
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 4 }} onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          <StyledCard>
            <CardContent>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                  <CircularProgress sx={{ color: '#008080' }} />
                </Box>
              ) : (
                <>
                <Stepper activeStep={activeStep} sx={{ 
                  mb: 4,
                  '& .MuiStepLabel-root .Mui-active': {
                    color: '#008080'
                  },
                  '& .MuiStepLabel-root .Mui-completed': {
                    color: '#008080'
                  },
                  '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
                    borderColor: '#008080'
                  },
                  '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
                    borderColor: '#008080'
                  }
                }}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {renderStepContent(activeStep)}

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    startIcon={<NavigateBeforeIcon />}
                    sx={{ 
                      color: '#008080',
                      '&:not(:disabled)': {
                        borderColor: '#008080',
                        '&:hover': {
                          borderColor: '#006666',
                          backgroundColor: 'rgba(0, 128, 128, 0.04)'
                        }
                      }
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                    endIcon={activeStep === steps.length - 1 ? null : <NavigateNextIcon />}
                    disabled={loading}
                    sx={{ 
                      backgroundColor: '#008080',
                      '&:hover': {
                        backgroundColor: '#006666'
                      }
                    }}
                  >
                    {activeStep === steps.length - 1 ? 'Submit Assessment' : 'Next'}
                  </Button>
                </Box>
                </>
              )}
            </CardContent>
          </StyledCard>
        </SectionContainer>
      </FormContainer>
    );
  }

  if (showGuide) {
    return (
      <FormContainer>
        <SectionContainer>
          <SectionHeading variant="h4" gutterBottom>
            Scope 3 Emissions Assessment Guide
          </SectionHeading>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Understanding and measuring your value chain emissions
          </Typography>

          <Alert severity="info" sx={{ mb: 4 }}>
            Scope 3 emissions typically account for more than 70% of a business's carbon footprint. 
            This guide will help you assess and measure these emissions effectively.
          </Alert>

          <Grid container spacing={4}>
            {/* Understanding Scope 3 */}
            <Grid item xs={12}>
              <StyledCard>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <AssessmentIcon sx={{ mr: 2, color: '#008080' }} />
                    <Typography variant="h5">Understanding Scope 3 Emissions</Typography>
                  </Box>
                  <Typography paragraph>
                    Scope 3 emissions are all indirect emissions that occur in your company's value chain. 
                    These include both upstream and downstream emissions.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
                          Upstream Activities Include:
                        </Typography>
                        <List dense>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="1. Purchased Goods and Services" 
                              secondary="Raw materials, packaging, office supplies"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="2. Capital Goods" 
                              secondary="Equipment, buildings, vehicles"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="3. Fuel and Energy Activities" 
                              secondary="Not included in Scope 1 or 2"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="4. Upstream Transportation" 
                              secondary="Inbound logistics and distribution"
                            />
                          </ListItem>
                        </List>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
                          Downstream Activities Include:
                        </Typography>
                        <List dense>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="9. Downstream Transportation" 
                              secondary="Outbound logistics and distribution"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="10. Processing of Sold Products" 
                              secondary="Further processing by customers"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="11. Use of Sold Products" 
                              secondary="Product use phase emissions"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="12. End-of-Life Treatment" 
                              secondary="Disposal and recycling of products"
                            />
                          </ListItem>
                        </List>
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Assessment Methods */}
            <Grid item xs={12}>
              <StyledCard>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <TimelineIcon sx={{ mr: 2, color: '#008080' }} />
                    <Typography variant="h5">Assessment Methods</Typography>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                      <Paper sx={{ p: 2, bgcolor: 'grey.50', height: '100%' }}>
                        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
                          Spend-Based Method
                        </Typography>
                        <Typography variant="body2">
                          • Easiest to start with<br />
                          • Uses financial data<br />
                          • Good for initial screening<br />
                          • Lower accuracy
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Paper sx={{ p: 2, bgcolor: 'grey.50', height: '100%' }}>
                        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
                          Average-Data Method
                        </Typography>
                        <Typography variant="body2">
                          • Uses activity data<br />
                          • Industry average factors<br />
                          • Medium accuracy<br />
                          • Good for most categories
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Paper sx={{ p: 2, bgcolor: 'grey.50', height: '100%' }}>
                        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
                          Hybrid Method
                        </Typography>
                        <Typography variant="body2">
                          • Combines methods<br />
                          • Better accuracy<br />
                          • More effort required<br />
                          • Good for key categories
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Paper sx={{ p: 2, bgcolor: 'grey.50', height: '100%' }}>
                        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
                          Supplier-Specific
                        </Typography>
                        <Typography variant="body2">
                          • Highest accuracy<br />
                          • Primary data<br />
                          • Most effort required<br />
                          • Best for key suppliers
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Best Practices */}
            <Grid item xs={12}>
              <StyledCard>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <TipsAndUpdatesIcon sx={{ mr: 2, color: '#008080' }} />
                    <Typography variant="h5">Best Practices</Typography>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Paper sx={{ p: 2, bgcolor: 'grey.50', height: '100%' }}>
                        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
                          Getting Started
                        </Typography>
                        <List dense>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="1. Start with spend data"
                              secondary="Use financial records to get a quick overview"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="2. Focus on big impacts"
                              secondary="Identify your largest emission sources"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="3. Use available data"
                              secondary="Don't wait for perfect data to start"
                            />
                          </ListItem>
                        </List>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Paper sx={{ p: 2, bgcolor: 'grey.50', height: '100%' }}>
                        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
                          Data Collection
                        </Typography>
                        <List dense>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="1. Map your suppliers"
                              secondary="Identify key data sources"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="2. Engage departments"
                              secondary="Work with Finance, Procurement, etc."
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="3. Document everything"
                              secondary="Keep track of assumptions and methods"
                            />
                          </ListItem>
                        </List>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Paper sx={{ p: 2, bgcolor: 'grey.50', height: '100%' }}>
                        <Typography variant="subtitle1" sx={{ color: '#008080' }} gutterBottom>
                          Continuous Improvement
                        </Typography>
                        <List dense>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="1. Refine methods"
                              secondary="Gradually improve data quality"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="2. Engage suppliers"
                              secondary="Work towards primary data"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary="3. Regular updates"
                              secondary="Keep your inventory current"
                            />
                          </ListItem>
                        </List>
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" gap={2} mt={4}>
            <Button 
              variant="contained" 
              size="large"
              startIcon={<BarChartIcon />}
              onClick={handleStartAssessment}
              sx={{ 
                backgroundColor: '#008080',
                '&:hover': {
                  backgroundColor: '#006666'
                }
              }}
            >
              Go to Assessment
            </Button>
            <Button 
              variant="outlined"
              size="large"
              sx={{ 
                color: '#008080',
                borderColor: '#008080',
                '&:hover': {
                  borderColor: '#006666',
                  backgroundColor: 'rgba(0, 128, 128, 0.04)'
                }
              }}
            >
              Download Guide
            </Button>
          </Box>
        </SectionContainer>
      </FormContainer>
    );
  }

  // Add the DetailsDialog component
  const DetailsDialog = () => {
    if (!selectedAssessment) return null;

    const category = SCOPE3_CATEGORIES.find(c => c.id === selectedAssessment.category);

    return (
      <Dialog
        open={showDetailsDialog}
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ bgcolor: '#008080', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            Category {selectedAssessment.category}: {selectedAssessment.categoryName}
          </Typography>
          <IconButton onClick={handleCloseDetails} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                <Typography variant="subtitle1" sx={{ color: '#008080', mb: 1 }}>Assessment Details</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Assessment Date</Typography>
                    <Typography variant="body1" gutterBottom>
                      {new Date(selectedAssessment.date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Relevance</Typography>
                    <Box sx={{ 
                      display: 'inline-block',
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      bgcolor: selectedAssessment.relevance === 'high' ? 'error.50' :
                              selectedAssessment.relevance === 'medium' ? 'warning.50' : 'success.50',
                      color: selectedAssessment.relevance === 'high' ? 'error.700' :
                             selectedAssessment.relevance === 'medium' ? 'warning.700' : 'success.700'
                    }}>
                      <Typography variant="body2" fontWeight="500">
                        {selectedAssessment.relevance === 'high' ? 'High Priority' :
                         selectedAssessment.relevance === 'medium' ? 'Medium Priority' : 'Low Priority'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                <Typography variant="subtitle1" sx={{ color: '#008080', mb: 1 }}>Data Availability</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle2" color="text.secondary">Spend Data</Typography>
                    <Box sx={{ 
                      display: 'inline-block',
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      bgcolor: selectedAssessment.spendData === 'complete' ? 'success.50' :
                              selectedAssessment.spendData === 'partial' ? 'warning.50' : 'error.50',
                      color: selectedAssessment.spendData === 'complete' ? 'success.700' :
                             selectedAssessment.spendData === 'partial' ? 'warning.700' : 'error.700'
                    }}>
                      {selectedAssessment.spendData === 'complete' ? 'Complete' :
                       selectedAssessment.spendData === 'partial' ? 'Partial' : 'Not Available'}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle2" color="text.secondary">Activity Data</Typography>
                    <Box sx={{ 
                      display: 'inline-block',
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      bgcolor: selectedAssessment.activityData === 'complete' ? 'success.50' :
                              selectedAssessment.activityData === 'partial' ? 'warning.50' : 'error.50',
                      color: selectedAssessment.activityData === 'complete' ? 'success.700' :
                             selectedAssessment.activityData === 'partial' ? 'warning.700' : 'error.700'
                    }}>
                      {selectedAssessment.activityData === 'complete' ? 'Complete' :
                       selectedAssessment.activityData === 'partial' ? 'Partial' : 'Not Available'}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle2" color="text.secondary">Supplier Data</Typography>
                    <Box sx={{ 
                      display: 'inline-block',
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      bgcolor: selectedAssessment.supplierData === 'complete' ? 'success.50' :
                              selectedAssessment.supplierData === 'partial' ? 'warning.50' : 'error.50',
                      color: selectedAssessment.supplierData === 'complete' ? 'success.700' :
                             selectedAssessment.supplierData === 'partial' ? 'warning.700' : 'error.700'
                    }}>
                      {selectedAssessment.supplierData === 'complete' ? 'Complete' :
                       selectedAssessment.supplierData === 'partial' ? 'Partial' : 'Not Available'}
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {selectedAssessment.comments && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                  <Typography variant="subtitle1" sx={{ color: '#008080', mb: 1 }}>Additional Notes</Typography>
                  <Typography variant="body1">{selectedAssessment.comments}</Typography>
                </Paper>
              </Grid>
            )}

            {category && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                  <Typography variant="subtitle1" sx={{ color: '#008080', mb: 1 }}>Category Information</Typography>
                  <Typography variant="subtitle2" color="text.secondary">Description</Typography>
                  <Typography variant="body1" paragraph>{category.description}</Typography>
                  
                  <Typography variant="subtitle2" color="text.secondary">Recommended Method</Typography>
                  <Typography variant="body1" paragraph>{category.recommended_method}</Typography>
                  
                  <Typography variant="subtitle2" color="text.secondary">Required Data</Typography>
                  <List dense>
                    {category.data_needed.map((data, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon sx={{ color: '#008080' }} />
                        </ListItemIcon>
                        <ListItemText primary={data} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2, bgcolor: 'grey.50' }}>
          <Button 
            onClick={() => handleEditAssessment(selectedAssessment)}
            startIcon={<EditIcon />}
            sx={{ 
              color: '#008080',
              borderColor: '#008080',
              '&:hover': {
                borderColor: '#006666',
                backgroundColor: 'rgba(0, 128, 128, 0.04)'
              }
            }}
          >
            Edit Assessment
          </Button>
          <Button 
            onClick={() => handleDeleteAssessment(selectedAssessment._id)}
            startIcon={<DeleteIcon />}
            color="error"
          >
            Delete Assessment
          </Button>
          <Button 
            variant="contained"
            onClick={handleCloseDetails}
            sx={{ 
              backgroundColor: '#008080',
              '&:hover': {
                backgroundColor: '#006666'
              }
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Add the DetailsDialog to the AssessmentResults component
  {showDetailsDialog && <DetailsDialog />}
};

export default Scope3Guidance;
