// client/src/pages/Settings/Scope3InitialAssessmentSettingsPage.js
import React from 'react';
import Scope3InitialAssessmentForm from '../../components/Settings/Scope3InitialAssessmentForm';

const Scope3InitialAssessmentSettingsPage = () => {
  return (
    <div>
      <Scope3InitialAssessmentForm />
    </div>
  );
};

export default Scope3InitialAssessmentSettingsPage;