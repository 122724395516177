// Constants and initial states
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
export const MIN_REFRESH_INTERVAL = 2000; // 2 seconds
export const MAX_RETRIES = 3;
export const RETRY_DELAY = 1000; // 1 second

export const ENDPOINTS = {
  scope1: {
    stationaryCombustion: '/api/scope1/stationary-combustion',
    mobileCombustionLandFB: '/api/scope1/mobile-combustion-land-fb',
    mobileCombustionLandDB: '/api/scope1/mobile-combustion-land-db',
    fugitiveEmissions: '/api/scope1/fugitive-emissions',
    processEmissions: '/api/scope1/process-emissions'
  },
  scope2: {
    purchasedElectricityLB: '/api/scope2/purchased-electricity-lb',
    purchasedHeating: '/api/scope2/purchased-heating'
  },
  scope3: {
    wasteGenerated: '/api/scope3/waste-generated',
    businessTravelLandDB: '/api/scope3/business-travel-land-db',
    employeeCommutingLandDB: '/api/scope3/employee-commuting-land-db',
    upstreamLeasedAssets: '/api/scope3/upstream-leased-assets-AS'
  },
  baseYear: '/api/base-year',
  companyInfo: '/api/company-info',
  organizationalBoundary: '/api/organizational-boundary'
};

const createEmptyCategory = () => ({
  data: [],
  lastUpdated: null,
  loading: false,
  error: null
});

export const initialEmissionsData = {
  scope1: {
    stationaryCombustion: createEmptyCategory(),
    mobileCombustion: createEmptyCategory(),
    fugitiveEmissions: createEmptyCategory()
  },
  scope2: {
    purchasedElectricity: createEmptyCategory(),
    purchasedHeating: createEmptyCategory()
  },
  scope3: {
    businessTravelLandDB: createEmptyCategory(),
    employeeCommutingLandDB: createEmptyCategory(),
    upstreamLeasedAssets: createEmptyCategory()
  }
};

export const initialBaseYearData = {
  current: {
    scope1_total: 0,
    scope2_total_location: 0,
    scope2_total_market: 0,
    scope3_total: 0,
    total_emissions_location: 0,
    total_emissions_market: 0,
    year: "2020",
    change: {
      value: "0.0",
      isIncrease: false
    }
  },
  loading: false,
  error: null
};

export const getInitialDateRange = () => {
  const end = new Date();
  const start = new Date();
  start.setFullYear(start.getFullYear() - 1);
  return { start, end };
};
